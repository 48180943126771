/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * RFS
 * API documentation for RFS Athena

 * OpenAPI spec version: 0.0.1
 */

export type GetS44sSort = (typeof GetS44sSort)[keyof typeof GetS44sSort];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetS44sSort = {
  nameAsc: "nameAsc",
  nameDesc: "nameDesc",
} as const;
