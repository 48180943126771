import { media } from "@app/design-system";
import styled from "styled-components";

type PageLayoutVariant = "default" | "weak" | "medium";
type PageLayoutSize = "sm" | "md";

interface StyledPageLayoutProps {
  size: PageLayoutSize;
  variant: PageLayoutVariant;
}

const StyledPageLayout = styled.div<StyledPageLayoutProps>`
  display: flex;
  padding: 1rem;
  flex: 1;
  flex-direction: column;
  background-color: ${(p) => {
    switch (p.variant) {
      case "weak": {
        return p.theme.colors.neutrals.backgroundWeak;
      }
      case "medium": {
        return p.theme.colors.neutrals.backgroundMedium;
      }
      default: {
        return p.theme.colors.neutrals.background;
      }
    }
  }};

  @media ${media.lg} {
    padding: 1.5rem;
    width: 100%;
    max-width: ${(p) => (p.size === "sm" ? "80rem" : "120rem")};
    margin: 0 auto;
    border-top: none;
    border-bottom: none;
  }
`;

interface PageLayoutProps {
  children?: React.ReactNode;
  size?: PageLayoutSize;
  variant?: PageLayoutVariant;
}

const PageLayout = ({
  children,
  size = "md",
  variant = "default",
}: PageLayoutProps) => {
  return (
    <StyledPageLayout size={size} variant={variant}>
      {children}
    </StyledPageLayout>
  );
};

export default PageLayout;
