import { LeftChevron, NavHeaderButton } from "@app/design-system";
import BackButton from "../../util/BackButton/BackButton";

interface NavHeaderBackButtonProps {
  parentHref: string;
}

const NavHeaderBackButton = ({ parentHref }: NavHeaderBackButtonProps) => {
  return (
    <BackButton
      href={parentHref}
      legacyBehavior
      render={(props) => (
        <NavHeaderButton
          icon={LeftChevron}
          label="Back"
          data-testid="nav-back-button"
          {...props}
        />
      )}
      data-testid="nav-back-button"
    />
  );
};

export default NavHeaderBackButton;
